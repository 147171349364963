import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './containers';

import { CanActivateAuthGuard } from './services/security/can-activate.authguard';
import { UnAuthGuard } from './services/security/unauth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },

  {

    path: 'auth',
    loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule),
    // canActivate: [UnAuthGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./views/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'dashboard',
    component: DefaultLayoutComponent,
    data: {
      title: 'Dashboard'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/dashboard/index/index.module').then(m => m.DashboardIndexModule)
      },
      
      {
        path: 'services',
        // data: {
        //   allowedRoles: ['ADMIN', 'CONTENT']
        // },
        loadChildren: () => import('./views/dashboard/services/services.module').then(m => m.DashboardServicesModule)
      },
     
      {
        path: 'customer-management',
        // data: {
        //   allowedRoles: ['ADMIN', 'CONTENT', 'LKBANK']
        // },
        loadChildren: () => import('./views/dashboard/customer-management/customer-management.module').then(m => m.DashboardConsumerManagementModule)
      },
      {
        path: 'human-resource',
        // data: {
        //   allowedRoles: ['ADMIN', 'CONTENT', 'LKBANK']
        // },
        loadChildren: () => import('./views/dashboard/human-resource/human-resource.module').then(m => m.DashboardHumanResourceModule)
      },
      {
        path: 'sale-management',
        data: {
          allowedRoles: ['ADMIN'],
        },
        loadChildren: () => import('./views/dashboard/sale-management/sale-management.module').then(m => m.DashboardSaleManagementModule)
      },
      {
        path: 'procurement-management',
        data: {
          allowedRoles: ['ADMIN'],
        },
        loadChildren: () => import('./views/dashboard/procurement-manager/procurement-manager.module').then(m => m.DashboardProcurementManagementModule)
      },
      {
        path: 'warehouse',
        data: {
          allowedRoles: ['ADMIN']
        },
        loadChildren: () => import('./views/dashboard/warehouse-management/warehouse-management.module').then(m => m.DashboardWarehouseManagementModule)
      },
      {
        path: 'manufacture-management',
        data: {
          allowedRoles: ['ADMIN']
        },
        loadChildren: () => import('./views/dashboard/manufacture-management/manufacture-management.module').then(m => m.DashboardManufactureManagementModule)
      },
      {
        path: 'issue-management',
        data: {
          allowedRoles: ['ADMIN']
        },
        loadChildren: () => import('./views/dashboard/issue-management/issue-management.module').then(m => m.DashboardIssueManagementModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [CanActivateAuthGuard, UnAuthGuard]
})
export class AppRoutingModule { }
