import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SweetAlertService } from 'angular-sweetalert-service';
import { TranslateService } from '@ngx-translate/core';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {


  constructor(private _alert: SweetAlertService, private _translate: TranslateService) {}

  async canDeactivate(component: ComponentCanDeactivate) {// : boolean | Observable<boolean>
    if (component.canDeactivate()) {
      return true;
    } else {
      // return confirm('Êtes-vous sûr de vouloir quitter?');
      const cancelBtnText = await this._translate.get('COMMON.LABEL.BUTTON.NON').toPromise();
      const okBtnText = await this._translate.get('COMMON.LABEL.BUTTON.OUI').toPromise();
      return this._alert.confirm({
        showConfirmButton: true,
        confirmButtonText: okBtnText,
        cancelButtonText: cancelBtnText,
        confirmButtonColor: '#E75113',
        reverseButtons: true,
        text: 'Êtes-vous sûr de vouloir quitter?'
      }).then((result: any) => {
        return result.value;
      });
    }
  }
}
