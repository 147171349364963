import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { navItems } from './_nav';
import { LoadingService } from '../../services/loading.service';
import { TokenStorage } from '@app/services/token.storage';
// import { ApplicationsService } from '@app/services/apps.service';
import { ApiService } from '@app/services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {
  public isLoading = null;
  public navItems = null;
  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };
  public currentUser;
  // public app = inject(ApplicationsService)
  public apiService = inject(ApiService);
  public constructor(private loadingService: LoadingService, private tokenStorage: TokenStorage) {}

  ngOnInit() {
    // this.apiService.list('warehouse', undefined, undefined).pipe(switchMap(res => {
    //   const data = res.content && res.content.map(item => ({ idWarehouse: item.idWarehouse, name: item.name }))
    //   return of(data);
    // })).subscribe(res => {
    //   this.app.warehouseList.next(res);
    // });
    this.isLoading = this.loadingService.isLoading$;
    this.currentUser = this.tokenStorage.getCurrentUser();
    this.navItems = navItems.filter((item) => (item.role + '').includes(this.currentUser.role));
  }
}
