import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClaimGift } from '../model/claim-gift.model';
import { Consumer } from '../model/consumer.model';
import { Gift } from '../model/gift.model';
import { Pet } from '../model/pet.model';
import { Product } from '../model/product.model';
import { QRCode } from '../model/qrcode.model';
import { Rating } from '../model/rating.model';
import { ScanQRCode } from '../model/scan-qrcode.model';
import { Shop } from '../model/shop.model';

import { Staff } from '../model/staff.model';
import { Tip } from '../model/tip.model';

@Injectable()
export class DataDemoService {

    constructor(private http: HttpClient) { }
    getStaffs() {
        return this.http.get<any>('assets/demo-data/staffs.json')
        .toPromise()
        .then(res => <Staff[]>res.data)
        .then(data => { return data; });
    }
    getProducts() {
        return this.http.get<any>('assets/demo-data/products.json')
        .toPromise()
        .then(res => <Product[]>res.data)
        .then(data => { return data; });
    }
    getConsumers() {
        return this.http.get<any>('assets/demo-data/consumers.json')
        .toPromise()
        .then(res => <Consumer[]>res.data)
        .then(data => { return data; });
    }
    getPets() {
        return this.http.get<any>('assets/demo-data/pets.json')
        .toPromise()
        .then(res => <Pet[]>res.data)
        .then(data => { return data; });
    }
    getShops() {
        return this.http.get<any>('assets/demo-data/shops.json')
        .toPromise()
        .then(res => <Shop[]>res.data)
        .then(data => { return data; });
    }
    getGifts() {
        return this.http.get<any>('assets/demo-data/gifts.json')
        .toPromise()
        .then(res => <Gift[]>res.data)
        .then(data => { return data; });
    }
    getQRCodes() {
        return this.http.get<any>('assets/demo-data/qrcodes.json')
        .toPromise()
        .then(res => <QRCode[]>res.data)
        .then(data => { return data; });
    }
    getScanQRCodes() {
        return this.http.get<any>('assets/demo-data/scan-qrcodes.json')
        .toPromise()
        .then(res => <ScanQRCode[]>res.data)
        .then(data => { return data; });
    }
    getClaimGifts() {
        return this.http.get<any>('assets/demo-data/claim-gifts.json')
        .toPromise()
        .then(res => <ClaimGift[]>res.data)
        .then(data => { return data; });
    }
    getTips() {
        return this.http.get<any>('assets/demo-data/tips.json')
        .toPromise()
        .then(res => <Tip[]>res.data)
        .then(data => { return data; });
    }
    getNotifications() {
        return this.http.get<any>('assets/demo-data/notifications.json')
        .toPromise()
        .then(res => <Notification[]>res.data)
        .then(data => { return data; });
    }
    getRatings() {
        return this.http.get<any>('assets/demo-data/ratings.json')
        .toPromise()
        .then(res => <Rating[]>res.data)
        .then(data => { return data; });
    }
    getFavShopChange() {
        return this.http.get<any>('assets/demo-data/fav-shop-change.json')
        .toPromise()
        .then(res => <any[]>res.data)
        .then(data => { return data; });
    }
}