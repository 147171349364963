import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSource = new BehaviorSubject<boolean>(false)

  isLoading$ = this.loadingSource.asObservable()

  constructor() {}

  setLoading(v) {
    this.loadingSource.next(v)
  }
}
