import { Injectable } from '@angular/core';

const TOKEN_TIMEOUT = 'timeout';
const TOKEN_EXPIRES_IN = 'expiresIn';
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'current-user';

@Injectable()
export class TokenStorage {

    storage: any;

    constructor() {
        this.storage = window.sessionStorage;
    }

    clear() {
        this.storage.removeItem(TOKEN_KEY);
        this.storage.removeItem(USER_KEY);
        this.storage.removeItem(TOKEN_TIMEOUT);
        this.storage.removeItem(TOKEN_EXPIRES_IN);
        this.storage.clear();
    }

    public save(token: string, currentUser: any, timeout: number) {
        this.clear();

        this.storage.setItem(TOKEN_KEY, token);
        this.storage.setItem(USER_KEY, JSON.stringify(currentUser));
        this.storage.setItem(TOKEN_TIMEOUT, timeout);
        this.storage.setItem(TOKEN_EXPIRES_IN, Date.now() + 1000 * 60 * timeout);
    }

    public saveUser(currentUser: any) {
        this.storage.setItem(USER_KEY, JSON.stringify(currentUser));
    }

    public getToken(): string {
        // console.log(this.storage.getItem(TOKEN_KEY));
        return this.storage.getItem(TOKEN_KEY);
    }

    public getCurrentUser(): string {
        return JSON.parse(this.storage.getItem(USER_KEY));
    }

    public saveExpiresIn(expiresIn: number) {
        this.storage.setItem(TOKEN_EXPIRES_IN, Date.now() + 1000 * expiresIn);
    }

    public getExpiresIn(): number {
        return JSON.parse(this.storage.getItem(TOKEN_EXPIRES_IN));
    }

    public getTimeout(): number {
        return JSON.parse(this.storage.getItem(TOKEN_TIMEOUT));
    }
}
