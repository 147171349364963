import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent } from './containers';

import { TokenService } from '@services/token.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '@services/auth.service';
import { SweetAlertService } from 'angular-sweetalert-service/js';
import { TokenStorage } from '@services/token.storage';
import { AuthInterceptor } from '@services/security/auth.interceptor';
import { CanActivateAuthGuard } from '@services/security/can-activate.authguard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PendingChangesGuard } from '@services/security/pending-changes.guard';
import { ContextMenuModule } from 'primeng/contextmenu';

import { AppRoutingModule } from './app.routing';

import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  ListGroupModule,
  NavModule,
  ProgressModule,
  SharedModule,
  SidebarModule,
  TableModule,
  TabsModule,
  UtilitiesModule,
} from '@coreui/angular';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgChartsModule } from 'ng2-charts';
import { DataDemoService } from './services/data-demo.service';
import { ApiService } from './services/api.service';
import { InputTextModule } from 'primeng/inputtext';
// import { ApplicationsService } from './services/apps.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    UtilitiesModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    SharedModule,
    FormModule,
    GridModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // WaittingModule,
    HttpClientModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BsDropdownModule.forRoot(),
    NgChartsModule,
    BadgeModule,
    BreadcrumbModule,
    HeaderModule,
    FooterModule,
    IconModule,
    PerfectScrollbarModule,
    SidebarModule,
    IconModule,
    IconSetModule.forRoot(),
    AvatarModule,
    EditorModule,
    TableModule,
    InputTextModule,
    FormsModule,
    MultiSelectModule,
    ContextMenuModule,
    // EntitySelectionDialogModule,
    // NotifyScoreModule
  ],
  declarations: [AppComponent, ...APP_CONTAINERS],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // ApplicationsService,
    TokenService,
    AuthenticationService,
    SweetAlertService,
    TokenStorage,
    CanActivateAuthGuard,
    // EntityService,
    PendingChangesGuard,
    IconSetService,
    DataDemoService,
    ApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
