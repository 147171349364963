import { INavData } from '@coreui/angular';
import { MyINavData } from './MyINavData';

export const navItems: MyINavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard/index',
    icon: 'fa fa-bar-chart-o',
  },
  {
    name: 'Quản lý tài khoản',
    url: '/dashboard/human-resource',
    icon: 'fa fa-sitemap',
    role: 'ADMIN',
    children: [
      {
        name: 'Staffs',
        url: '/dashboard/human-resource/staffs/list',
        icon: 'fa fa-user-circle'
      },
    ]
  },
  {
    name: 'Quản lý CMR',
    url: '/dashboard/customer-management',
    icon: 'fa fa-users',
    role: 'ADMIN, LKBANK',
    children: [
      {
        name: 'Danh nghiệp',
        url: '/dashboard/customer-management/agency',
        icon: 'fa fa-user-o',
      },
      {
        name: 'Khách hàng',
        url: '/dashboard/customer-management/customer',
        icon: 'fa fa-user-o',
      },
      {
        name: 'Nhà Cung Cấp',
        url: '/dashboard/customer-management/partner-supplier',
        icon: 'fa fa-address-card',
      },
      {
        name: 'Đối tác gia công',
        url: '/dashboard/customer-management/partner-manufacture',
        icon: 'fa fa-building',
      },
      {
        name: 'Đối tác vận chuyển',
        url: '/dashboard/customer-management/partner-transport',
        icon: 'fa fa-truck',
      },
    ]
  },
  {
    name: 'Quản lý kho',
    url: '/dashboard/warehouse',
    icon: 'fa fa-university',
    role: 'ADMIN',
    children: [
      {
        name: 'Hàng lỗi',
        url: '/dashboard/warehouse/product-defective',
        icon: 'fa fa-bitbucket-square',
      },
      {
        name: 'Thành phẩm',
        url: '/dashboard/warehouse/product-stock',
        icon: 'fa fa-cube'
      },
      {
        name: 'Vải',
        url: '/dashboard/warehouse/fabric',
        icon: 'fa fa-map-o'
      },
      {
        name: 'Vật tư',
        url: '/dashboard/warehouse/material',
        icon: 'fa fa-modx'
      },
      {
        name: 'Nhập kho',
        url: '/dashboard/warehouse/income',
        icon: 'fa fa-ambulance'
      },
      {
        name: 'Xuất kho',
        url: '/dashboard/warehouse/outcome',
        icon: 'fa fa-truck'
      }
    ]
  },
  {
    name: 'Sản xuất',
    url: '/dashboard/manufacture-management',
    icon: 'fa fa-rocket',
    role: 'ADMIN',
    children: [
      {
        name: 'BOM',
        url: '/dashboard/manufacture-management/bom',
        icon: 'fa fa-cubes',
      },
      {
        name: 'Kế hoạch sản xuất',
        url: '/dashboard/manufacture-management/plan',
        icon: 'fa fa-leanpub',
      },
      {
        name: 'Lệnh sản xuất',
        url: '/dashboard/manufacture-management/command',
        icon: 'fa fa-calendar',
      },
      {
        name: 'Nghiệm thu',
        url: '/dashboard/manufacture-management/quality',
        icon: 'fa fa-desktop',
      },
      {
        name: 'Mẫu thiết kế',
        url: '/dashboard/manufacture-management/product-design',
        icon: 'fa fa-medium',
      },
    ]
  },
  {
    name: 'Mua Hàng',
    url: '/dashboard/procurement-management',
    icon: 'fa fa-medkit',
    role: 'ADMIN',
    children: [
      {
        name: 'Kế hoạch mua hàng',
        url: '/dashboard/procurement-management/supplies-buy-plan',
        icon: 'fa fa-cart-plus',
      },
      {
        name: 'báo giá NCC',
        url: '/dashboard/procurement-management/supplier-quota',
        icon: 'fa fa-pencil-square-o',
      },
    ]
  },
  {
    name: 'Bán hàng',
    url: '/dashboard/sale-management',
    icon: 'fa fa-shopping-cart',
    role: 'ADMIN',
    children: [
      {
        name: 'Đơn bán hàng',
        url: '/dashboard/sale-management/order',
        icon: 'fa fa-cart-plus',
      },
      {
        name: 'Báo giá',
        url: '/dashboard/sale-management/order-quota',
        icon: 'fa fa-ticket',
      },
      {
        name: 'Sản phẩm',
        url: '/dashboard/sale-management/product',
        icon: 'fa fa-suitcase',
      },
      {
        name: 'Giao hàng',
        url: '/dashboard/sale-management/delivery',
        icon: 'fa fa-ambulance',
      }
    ]
  },
  {
    name: 'Phiếu yêu cầu',
    url: '/dashboard/issue-management',
    icon: 'fa fa-bullhorn',
    role: 'ADMIN',
    children: [
      {
        name: 'Sản xuất',
        url: '/dashboard/issue-management/manufacture-request',
        icon: 'fa fa-rocket',
      },
      {
        name: 'In logo',
        url: '/dashboard/issue-management/logo-request',
        icon: 'fa fa-print',
      },
      {
        name: 'Kiểm tra chất lượng',
        url: '/dashboard/issue-management/quality-request',
        icon: 'fa fa-search-plus',
      },
      // {
      //   name: 'Mua hàng',
      //   url: '/dashboard/issue-management/supplies-buy-request',
      //   icon: 'fa fa-cart-plus',
      // },
      {
        name: 'Nhập kho',
        url: '/dashboard/issue-management/warehouse-income-request',
        icon: 'fa fa-ambulance',
      },
      {
        name: 'Xuất kho',
        url: '/dashboard/issue-management/warehouse-outcome-request',
        icon: 'fa fa-sign-out',
      },
      {
        name: 'Giao hàng',
        url: '/dashboard/issue-management/delivery-request',
        icon: 'fa fa-truck',
      },
    ]
  },
  {
    name: 'Cấu hình hệ thống',
    url: '/dashboard/services',
    icon: 'fa fa-cogs',
    role: 'ADMIN',
    children: [
      // {
      //   name: 'Notifications',
      //   url: '/dashboard/services/notifications',
      //   icon: 'fa fa-bell',
      // },
      {
        name: 'warehouse',
        url: '/dashboard/services/warehouse',
        icon: 'fa fa-home',
      },
      // {
      //   name: 'Settings',
      //   url: '/dashboard/services/settings',
      //   icon: 'fa fa-cog',
      // }
    ]
  }
];
