import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SweetAlertService } from 'angular-sweetalert-service';
import { TranslateService } from '@ngx-translate/core';

// Interface yêu cầu các component có phương thức canDeactivate
export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private _alert: SweetAlertService, private _translate: TranslateService) {}

  // Phương thức canDeactivate để xác nhận hành động của người dùng
  async canDeactivate(component: ComponentCanDeactivate): Promise<boolean> {
    // Nếu component trả về true thì cho phép rời khỏi trang
    if (component.canDeactivate()) {
      return true;
    } else {
      try {
        // Lấy văn bản của các nút từ dịch vụ ngx-translate
        const cancelBtnText = "Không";
        const okBtnText = "Đồng ý";

        // Hiển thị alert xác nhận
        const result = await this._alert.confirm({
          showConfirmButton: true,
          confirmButtonText: okBtnText,
          cancelButtonText: cancelBtnText,
          confirmButtonColor: '#E75113',
          reverseButtons: true,
          text: 'Bạn cần lưu dữ liệu nếu có thay đổi. Bạn có muốn rời khỏi trang không?'
        });

        // Nếu người dùng chọn OK (result.value == true), cho phép rời khỏi
        return result.value;
      } catch (error) {
        console.error('Error fetching translations:', error);
        return false; // Nếu có lỗi khi lấy văn bản, không cho phép rời khỏi trang
      }
    }
  }
}