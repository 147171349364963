import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../auth.service';
import { DataService } from '../data.service';
import { TokenStorage } from '../token.storage';
@Injectable()
export class CanActivateAuthGuard implements CanActivate {
  constructor(private router: Router, private tokenStorage: TokenStorage, private authService: AuthenticationService, private _dataService: DataService) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
        let currentUser: any = this.tokenStorage.getCurrentUser();
        let hasRoles: any;
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            hasRoles = route.firstChild.data;    
            if(hasRoles.allowedRoles == null || hasRoles.allowedRoles == undefined) return true;
           let roles = hasRoles.allowedRoles.find((item) => item.includes(currentUser.role));
           if(roles == null){
            this.router.navigate(['/error/403']);
            return false;
           }
          }
        });
          return true;
    }
    
    
    this.router.navigate(['/auth/login']);
    return false;
  }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //     if (this.authService.isLoggedIn()) {
  //       return true;
  //     } else{
  //       this.router.navigate(['/login']);
  //       return false;
  //     }
  //     if (state.url.indexOf('error/exception') !== -1) {
  //       return false;
  //     }
  //     return false;
  // }
}
