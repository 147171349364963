import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TokenStorage } from '../token.storage';

import { DataService } from '../data.service';
import { catchError } from 'rxjs/operators';
import { LoadingService } from '../loading.service';
import { AuthenticationService } from '../auth.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenStorage,
    private router: Router,
    private _dataService: DataService,
    private loadingService: LoadingService,
    private auth: AuthenticationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const timer = this.token.getExpiresIn();
    // console.log(60*this.token.getTimeout());
    // console.log(timer);
    // if (timer && (Date.now() > timer)) {
    //   this.auth.logout();
    //   this.router.navigate(['/login']);
    // }
    let authReq = req;
    if (this.token.getToken() != null) {
      // this.token.saveExpiresIn(60 * this.token.getTimeout());
      // console.log('Intercepting .. with ' + this.token.getToken());
      // console.log(req);
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.token.getToken()) });
    }
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        this._dataService.setErrorCode(error);
        // this.router.navigate(['error/exception']);
        return throwError('exception');
      })
    );
  }
}
