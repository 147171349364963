import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { TokenStorage } from './token.storage';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {

    readonly endpoint: string = '/auth';

    constructor(private http: HttpClient, private _storage: TokenStorage) {
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}${this.endpoint}/login`, {
            username: username,
            password: password
        });
    }

    forgetPassword(username: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}${this.endpoint}/forgot-password`, {
            username: username
        });
    }

    loginSso(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${this.endpoint}/sso`);
    }

    saveLoginData(data) {
        const currentUser: any = data.currentUser;
        currentUser.authAdmin = data.authAdmin;
        this._storage.save(data.token, data.currentUser, data.timeout);
    }

    updateCurrentUser(currentUser) {
        this._storage.saveUser(currentUser);
    }

    getCurrentUser() {
        return this._storage.getCurrentUser();
    }

    isLoggedIn(): boolean {
        const token = this._storage.getToken();
        return token && token.length > 0;
    }

    logout(): void {
        this._storage.clear();
    }
}