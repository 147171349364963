import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DataService {

  private data: any = null;
  private node: any = null;
  private errorCode: any = null;
  private paginator: any = null;

  setData(data: any): any {
    this.data = data;
  }

  getData(): any {
    let data: any = this.data;
    this.data = null;

    return data;
  }

  setErrorCode(errorCode: any): any {
    this.errorCode = errorCode;
  }

  getErrorCode(): any {
    return this.errorCode;
  }

  setNode(node: any): any {
    this.node = node;
  }

  getNode(): any {
    return this.node;
  }

  setPaginator(paginator: any): any {
    this.paginator = paginator;
  }

  getPaginator(): any {
    const paginator: any = this.paginator;
    this.paginator = null;
    return paginator;
  }
  getDefaultPaginator(query): any {
    var sorts = query.sort && query.sort.split(",");
    if (!query.sort) return [];

    return {
      first: parseInt(query.page) * parseInt(query.size),
      sortField: sorts[0],
      sortOrder: sorts[1] == "asc" ? -1 : 1,
      rows: parseInt(query.size),
      last: 0
    };
  }
}
